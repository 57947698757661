var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "news", staticClass: "news" },
    [
      _c(
        "van-tabs",
        {
          attrs: {
            sticky: "",
            color: "#FFB606",
            "line-width": "20px",
            "line-height": "3px",
            "title-active-color": "#1A1A1A",
            "title-inactive-color": "#999999",
            border: ""
          },
          on: { click: _vm.vanTab },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { name: "a", title: "热点新闻" } },
            [_c("newList")],
            1
          ),
          _c("van-tab", { attrs: { name: "b", title: "市场快讯" } }, [
            _vm.shiChangLoad
              ? _c(
                  "div",
                  [
                    _c(
                      "center",
                      { attrs: { id: "loadingbox" } },
                      [_c("van-loading", { attrs: { size: "70px" } })],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.shiChangLoad
              ? _c("div", [
                  _c("iframe", {
                    ref: "iframe",
                    staticClass: "iframe",
                    attrs: {
                      sandbox: "allow-same-origin allow-scripts",
                      src: "https://www.jin10.com/",
                      width: "100%",
                      scrolling: "auto",
                      frameborder: "yes"
                    }
                  })
                ])
              : _vm._e()
          ]),
          _c("van-tab", { attrs: { name: "c", title: "财经日历" } }, [
            _vm.caiJingLoad
              ? _c(
                  "div",
                  [
                    _c(
                      "center",
                      { attrs: { id: "loadingbox" } },
                      [_c("van-loading", { attrs: { size: "70px" } })],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            !_vm.caiJingLoad
              ? _c("div", [
                  _c("iframe", {
                    ref: "iframe",
                    staticClass: "iframeOne",
                    attrs: {
                      security: "restricted",
                      sandbox: "allow-scripts allow-same-origin ",
                      src: "https://rili.jin10.com/",
                      width: "100%",
                      scrolling: "auto",
                      frameborder: "yes"
                    }
                  })
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }